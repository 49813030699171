import React from 'react';
import { getUrlImgStrapi } from '../../util/urlHelpers';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import InquireButton from '../InquireButton/InquireButton';
import NamedLink from '../NamedLink/NamedLink';
import { bool, func, number, object, string } from 'prop-types';
import classNames from 'classnames';
import { INQUIRE_PROJECT_TYPE } from '../../marketplace-custom-config';
import { limitString } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import config from '../../config';
import { createResourceLocatorString } from '../../util/routes';

import css from './ProjectCard.module.css';
import routeConfiguration from '../../routeConfiguration';

const VIDEO_PREFIX = 'video/';
const LIMIT_NUMBER_OF_RICH_TEXT = 15;

const ProjectImage = props => <img {...props} />;
const LazyImage = lazyLoadWithDimensions(ProjectImage, { loadAfterInitialRendering: 3000 });

const ProjectCard = props => {
  const {
    containerRef,
    id,
    className,
    inquireButtonClassName,
    textSliceLimit,
    showTestimonial,
    onResetEnquiryState,
    project,
  } = props;

  const { heroBanner, title, slug, tag, richText, testimonial } = project?.attributes || {};

  const banner = heroBanner?.data?.[0]?.attributes;
  const bannerUrl = getUrlImgStrapi(banner?.provider, banner?.url);
  const bannerAlt = banner?.alternativeText;
  const isVideoBanner = banner?.mime.includes(VIDEO_PREFIX);
  const limitRichText = limitString(richText, textSliceLimit);
  const limitTestimonial = limitString(testimonial, textSliceLimit);

  const hyperlink = `${config.canonicalRootURL}${createResourceLocatorString(
    'ProjectPostPage',
    routeConfiguration(),
    {
      slug,
    }
  )}`;

  return (
    <NamedLink
      name="ProjectPostPage"
      params={{ slug }}
      className={classNames(css.projectCard, className)}
    >
      <div className={css.threeToTwoWrapper}>
        <div className={css.aspectWrapper}>
          {isVideoBanner ? (
            <div className={css.videoWrapper}>
              <video className={css.video}>
                <source src={`${bannerUrl}#t=0.001`} type={banner?.mime} />
              </video>
            </div>
          ) : (
            <LazyImage
              className={css.rootForImage}
              alt={bannerAlt}
              src={bannerUrl}
              containerRef={containerRef}
            />
          )}
        </div>
      </div>
      <div>
        <div className={css.title}>{title}</div>
        <div className={css.tag}>{tag}</div>
        <InquireButton
          className={classNames(css.inquireButton, inquireButtonClassName)}
          modalId={`card-${id}`}
          title={title}
          type={INQUIRE_PROJECT_TYPE}
          onClose={onResetEnquiryState}
          hyperlink={hyperlink}
        />
        <div className={css.richText}>
          {showTestimonial
            ? limitTestimonial
            : limitRichText === richText
            ? limitRichText
            : `${limitRichText}...`}
        </div>
      </div>
    </NamedLink>
  );
};

ProjectCard.propTypes = {
  project: propTypes.project,
  containerRef: object,
  id: number,
  onManageDisableScrolling: func.isRequired,
  className: string,
  inquireButtonClassName: string,
  textSliceLimit: number,
  showTestimonial: bool,
  onResetEnquiryState: func.isRequired,
};

ProjectCard.defaultProps = {
  heroBanner: null,
  title: null,
  testimonial: null,
  tag: null,
  richText: null,
  containerRef: null,
  id: null,
  className: null,
  inquireButtonClassName: null,
  textSliceLimit: LIMIT_NUMBER_OF_RICH_TEXT,
  showTestimonial: false,
};

export default ProjectCard;
