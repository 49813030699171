import PropTypes from 'prop-types';
import React, { useRef, useState, useEffect } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { TopbarContainer } from '..';
import {
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  SectionHeading,
} from '../../components';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { useScrollDirection, withViewport } from '../../util/contextHelpers';
import { createSearchResultSchema } from './GalleryPage.helpers';
import { bannerStateKey } from '../../marketplace-custom-config';
import SectionExhibitions from './SectionExhibitions';
import SectionProjects from './SectionProjects';
import SectionPress from './SectionPress';
import { SecondaryButton } from '../../components/Button/Button';
import SectionCarousel from './SectionCarousel';
import FeaturedArtists from './FeaturedArtists';
import { resetEnquiryValues } from '../../ducks/Inquire.duck';

import css from './GalleryPage.module.css';

// Used in Recommendations
const MOBILE_BREAKPOINT = 768;

const GalleryPageComponent = props => {
  const {
    scrollingDisabled,
    intl,
    homepageData,
    queryHomepageDataInProgress,
    viewport,
    announcementContent,
    onManageDisableScrolling,
    featuredArtists,
    onResetEnquiryState,
  } = props;

  const {
    metaDescription,
    desktopCarousel,
    mobileCarousel,
    topWordingSection,
    exhibitionsSection,
    projectsSection,
    pressSection,
    artistsSection,
  } = homepageData || {};
  const isMobileLayout = viewport.width < MOBILE_BREAKPOINT;

  const { title, description, schema, facebookImages, twitterImages } = createSearchResultSchema({
    metaDescription,
    intl,
  });

  const pageRef = useRef(null);

  const { title: topSectionTitle, content: topSectionContent, ctaButton: topSectionButton } =
    topWordingSection || {};

  const handleClickButton = link => {
    if (typeof window !== 'undefined') {
      window.location.href = link;
    }
  };

  return (
    <Page
      contentClassName={css.pageContent}
      scrollingDisabled={scrollingDisabled}
      title={title}
      description={description}
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      schema={schema}
    >
      {/* ---------- Hero section ---------- */}
      <div className={css.heroContainer}>
        {viewport.width !== 0 && (
          <SectionCarousel
            heroData={{ desktopCarousel, mobileCarousel }}
            isLoading={queryHomepageDataInProgress}
            isMobileLayout={isMobileLayout}
          />
        )}
      </div>

      <LayoutSingleColumn className={css.container}>
        <LayoutWrapperTopbar>
          <TopbarContainer
            desktopClassName={css.topbarDesktop}
            navItemClassName={css.navItem}
            logoClassName={css.logo}
            searchClassName={css.search}
            loginButtonClassName={css.loginButton}
            currentPage="GalleryPage"
            announcementContent={announcementContent}
            bannerStateKey={bannerStateKey}
          />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.galleryContainer} ref={pageRef}>
          <div className={css.pageWrapper}>
            {/* ---------- Top Wording Section ---------- */}
            {topWordingSection ? (
              <div className={css.topWordingSection}>
                <SectionHeading
                  title={topSectionTitle}
                  content={topSectionContent}
                  titleClassName={css.sectionTitle}
                  titleTag="h2"
                />

                <SecondaryButton
                  className={css.secondaryButton}
                  onClick={() => handleClickButton(topSectionButton.link)}
                >
                  {topSectionButton.label}
                </SecondaryButton>
              </div>
            ) : null}

            {/* ---------- Artists ---------- */}
            <FeaturedArtists
              isLoading={queryHomepageDataInProgress}
              featuredArtists={featuredArtists}
              isMobileLayout={isMobileLayout}
              artistsSection={artistsSection}
              onResetEnquiryState={onResetEnquiryState}
            />

            {/* ---------- Gallery Exhibitions ---------- */}
            <SectionExhibitions
              exhibitionsSection={exhibitionsSection}
              isMobileLayout={isMobileLayout}
              intl={intl}
              onResetEnquiryState={onResetEnquiryState}
            />

            {/* ---------- Client Projects ---------- */}
            <SectionProjects
              projectsSection={projectsSection}
              isMobileLayout={isMobileLayout}
              onManageDisableScrolling={onManageDisableScrolling}
              onResetEnquiryState={onResetEnquiryState}
            />

            {/* ---------- News ---------- */}
            <SectionPress
              pressSection={pressSection}
              isMobileLayout={isMobileLayout}
              onResetEnquiryState={onResetEnquiryState}
            />
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, array, object, string } = PropTypes;

GalleryPageComponent.defaultProps = {
  articles: [],
  homepageData: null,
  featuredCollections: [],
  queryArticlesInProgress: false,
  queryHomepageDataInProgress: false,
  queryFeaturedCollectionsInProgress: false,
};

GalleryPageComponent.propTypes = {
  scrollingDisabled: bool,
  articles: array,
  homepageData: object,
  featuredCollections: array,
  queryArticlesInProgress: bool,
  queryHomepageDataInProgress: bool,
  queryFeaturedCollectionsInProgress: bool,

  // from injectIntl
  intl: intlShape,
};

const mapStateToProps = state => {
  const {
    articles,
    queryArticlesInProgress,
    homepageData,
    queryHomepageDataInProgress,
    featuredCollections,
    queryFeaturedCollectionsInProgress,
    announcementContent,
    featuredArtists,
  } = state.GalleryPage;

  const { authInfoLoaded } = state.Auth;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    authInfoLoaded,
    articles,
    queryArticlesInProgress,
    homepageData,
    queryHomepageDataInProgress,
    featuredCollections,
    queryFeaturedCollectionsInProgress,
    announcementContent,
    featuredArtists,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onResetEnquiryState: () => dispatch(resetEnquiryValues()),
});

const GalleryPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withViewport,
  injectIntl
)(GalleryPageComponent);

export default GalleryPage;
