import React from 'react';
import { string, bool } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import Form from '../../components/Form/Form';
import { PrimaryButton } from '../../components/Button/Button';
import FieldTextInput from '../../components/FieldTextInput/FieldTextInput';
import * as validators from '../../util/validators';
import { propTypes } from '../../util/types';
import NamedLink from '../../components/NamedLink/NamedLink';

import { useLocation } from 'react-router-dom';

import css from './EnquiryForm.module.css';

const EnquiryFormComponent = (props) => {
  const location = useLocation();
  const { error, ...rest } = props;
  return (
    <FinalForm
      {...rest}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          submitButtonWrapperClassName,
          handleSubmit,
          inProgress,
          intl,
          isAuthenticated,
        } = fieldRenderProps;

        // message
        const messageLabel = intl.formatMessage({ id: 'EnquiryForm.messageLabel' });
        const messagePlaceholder = intl.formatMessage({ id: 'EnquiryForm.messagePlaceholder' });
        const messageRequiredMessage = intl.formatMessage({ id: 'EnquiryForm.messageRequired' });
        const messageRequired = validators.requiredAndNonEmptyString(messageRequiredMessage);

        // name
        const nameLabel = intl.formatMessage({ id: 'EnquiryForm.nameLabel' });
        const namePlaceholder = intl.formatMessage({ id: 'EnquiryForm.namePlaceholder' });
        const nameRequiredMessage = intl.formatMessage({ id: 'EnquiryForm.nameRequired' });
        const nameRequired = validators.requiredAndNonEmptyString(nameRequiredMessage);

        // email
        const emailLabel = intl.formatMessage({ id: 'EnquiryForm.emailLabel' });
        const emailPlaceholder = intl.formatMessage({ id: 'EnquiryForm.emailPlaceholder' });
        const emailRequiredMessage = intl.formatMessage({ id: 'EnquiryForm.emailRequired' });
        const emailRequired = validators.requiredAndNonEmptyString(emailRequiredMessage);
        const emailInvalidMessage = intl.formatMessage({ id: 'EnquiryForm.emailInvalid' });
        const emailValid = validators.emailFormatValid(emailInvalidMessage);

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = submitInProgress;

        return (
          <Form
            className={classes}
            onSubmit={handleSubmit}
            enforcePagePreloadFor="OrderDetailsPage"
          >
            <FieldTextInput
              className={css.field}
              inputClassName={css.textAreaInput}
              successClassName={css.successMessage}
              errorClassName={css.errorMessage}
              type="textarea"
              name="message"
              id="message"
              label={messageLabel}
              placeholder={messagePlaceholder}
              validate={messageRequired}
            />

            {!isAuthenticated && (
              <>
                <FieldTextInput
                  className={css.name}
                  inputClassName={css.textInput}
                  successClassName={css.successMessage}
                  errorClassName={css.errorMessage}
                  name="name"
                  id="name"
                  label={nameLabel}
                  placeholder={namePlaceholder}
                  validate={nameRequired}
                />

                <FieldTextInput
                  className={css.email}
                  inputClassName={css.textInput}
                  successClassName={css.successMessage}
                  errorClassName={css.errorMessage}
                  name="email"
                  id="email"
                  label={emailLabel}
                  placeholder={emailPlaceholder}
                  validate={validators.composeValidators(emailRequired, emailValid)}
                />
              </>
            )}

            <div className={submitButtonWrapperClassName}>
              {error ? (
                <p className={css.error}>
                  <FormattedMessage id="EnquiryForm.error" />
                </p>
              ) : null}

              <PrimaryButton
                className={css.submitBtn}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
              >
                <FormattedMessage id="EnquiryForm.submitButtonText" />
              </PrimaryButton>

              {!isAuthenticated && (
                <p className={css.login}>
                  <FormattedMessage
                    id="EnquiryForm.haveAccount"
                    values={{
                      link: (
                        <NamedLink name="LoginPage" to={{ state: { from: location.pathname } }}>
                          <FormattedMessage id="EnquiryForm.login" />
                        </NamedLink>
                      ),
                    }}
                  />
                </p>
              )}
            </div>
          </Form>
        );
      }}
    />
  );
};

EnquiryFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  inProgress: false,
  error: null,
  isAuthenticated: false,
};

EnquiryFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,
  inProgress: bool,
  error: propTypes.error,
  isAuthenticated: bool,
  // from injectIntl
  intl: intlShape.isRequired,
};

const EnquiryForm = compose(injectIntl)(EnquiryFormComponent);

export default EnquiryForm;
