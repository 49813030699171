import React from 'react';
import NamedLink from '../../components/NamedLink/NamedLink';
import { getUrlImgStrapi } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import moment from 'moment';
import { intlShape } from 'react-intl';
import classNames from 'classnames';
import InquireButton from '../../components/InquireButton/InquireButton';
import config from '../../config';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { string } from 'prop-types';
import { INQUIRE_EXHIBITION_TYPE } from '../../marketplace-custom-config';

import css from './ExhibitionCard.module.css';

const today = moment();

const ExhibitionCard = ({ exhibition, intl, onResetEnquiryState }) => {
  const { heroImg, title, startDate, endDate, location, slug, galleryName } = exhibition || {};
  const { provider, url: urlByProvider, alternativeText } = heroImg?.data?.[0]?.attributes || {};
  const heroImgUrl = getUrlImgStrapi(provider, urlByProvider);

  const formatDate = date => {
    return moment(date, 'YYYY-MM-DD').format('ll');
  };
  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);

  const hyperlink = `${config.canonicalRootURL}${createResourceLocatorString(
    'ExhibitionsPostPage',
    routeConfiguration(),
    {
      slug,
    }
  )}`;

  const displayTag =
    moment(endDate, 'YYYY-MM-DD') < today ? (
      <div className={classNames(css.displayTag, css.past)}>
        {intl.formatMessage({ id: 'NewGalleryPage.pastExhibition' })}
      </div>
    ) : today >= moment(startDate, 'YYYY-MM-DD') ? (
      <div className={classNames(css.displayTag, css.onView)}>
        {intl.formatMessage({ id: 'NewGalleryPage.onViewExhibition' })}
      </div>
    ) : (
      <div className={classNames(css.displayTag, css.upcoming)}>
        {intl.formatMessage({ id: 'NewGalleryPage.upcomingExhibition' })}
      </div>
    );

  return (
    <div className={css.exhibitionsCard}>
      <NamedLink
        name="ExhibitionsPostPage"
        params={{
          slug,
        }}
      >
        <div className={css.imageAspect}>
          <div className={css.imageWrapper}>
            <img className={css.exhibitionsCardImage} src={heroImgUrl} alt={alternativeText} />
          </div>
        </div>
      </NamedLink>
      <NamedLink
        name="ExhibitionsPostPage"
        params={{
          slug,
        }}
      >
        <h3 className={css.exhibitionsCardTitle}>{title}</h3>
      </NamedLink>
      {displayTag}
      <InquireButton
        className={css.inquireButton}
        modalId={`exhibition-${slug}`}
        title={title}
        type={INQUIRE_EXHIBITION_TYPE}
        hyperlink={hyperlink}
        onClose={onResetEnquiryState}
      />
      <div className={css.exhibitionsGalleryName}>{galleryName}</div>
      <div className={css.exhibitionsCardDate}>
        {formattedStartDate} - {formattedEndDate}
      </div>
      <p className={css.exhibitionsCardLocation}>{location}</p>
    </div>
  );
};

ExhibitionCard.propTypes = {
  exhibition: propTypes.exhibition,
  intl: intlShape.isRequired,
  inquireType: string,
};

ExhibitionCard.defaultProps = {
  exhibition: null,
};

export default ExhibitionCard;
