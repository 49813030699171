export const limitString = (str = '', limit) => {
  const words = str.split(/[^\S\r\n]+/);
  const limitedWords = words.slice(0, limit);
  const limitedString = limitedWords.join(' ');
  return words.length > limitedWords.length ? `${limitedString}...` : limitedString;
};

export const limitStringByCharacters = (str = '', limit) => {
  if(!str) return str;
  const limitedString = str.slice(0, limit);
  return str.length > limitedString.length ? `${limitedString}...` : str;
};