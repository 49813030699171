import React from 'react';
import { getUrlImgStrapi } from '../../util/urlHelpers';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import InquireButton from '../InquireButton/InquireButton';
import ExternalLink from '../ExternalLink/ExternalLink';
import { bool, func, number, object, string } from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { INQUIRE_PRESS_TYPE } from '../../marketplace-custom-config';

import css from './PressCard.module.css';

const PressImage = props => <img {...props} />;
const LazyImage = lazyLoadWithDimensions(PressImage, { loadAfterInitialRendering: 3000 });

const LIMIT_NUMBER_OF_RICH_TEXT = 15;

const limitString = (str = '') => {
  const words = str.split(/\s+/);
  const limitedWords = words.slice(0, LIMIT_NUMBER_OF_RICH_TEXT);
  const limitedString = limitedWords.join(' ');
  return limitedString;
};

const PressCard = props => {
  const {
    heroImage,
    title,
    tag,
    publication,
    publishDate,
    externalUrl,
    containerRef,
    id,
    className,
    onClick,
    hideInquireButton,
    onResetEnquiryState,
  } = props;
  const image = heroImage.data.attributes;
  const imageUrl = getUrlImgStrapi(image.provider, image.url);
  const imageAlt = image.alternativeText;
  const limitPublication = limitString(publication);

  return (
    <div className={className}>
      <ExternalLink className={css.threeToTwoWrapper} href={externalUrl} onClick={onClick}>
        <div className={css.aspectWrapper}>
          <LazyImage
            className={css.rootForImage}
            alt={imageAlt}
            src={imageUrl}
            containerRef={containerRef}
          />
        </div>
      </ExternalLink>
      <div>
        <ExternalLink href={externalUrl} className={css.title} onClick={onClick}>
          {title}
        </ExternalLink>
        <div className={css.tag}>{tag}</div>
        {!hideInquireButton ? (
          <InquireButton
            modalId={id}
            title={title}
            hyperlink={externalUrl}
            type={INQUIRE_PRESS_TYPE}
            onClose={onResetEnquiryState}
          />
        ) : null}
        <div className={css.publication}>
          {limitPublication === publication ? limitPublication : `${limitPublication}...`}
        </div>
        <div className={css.publishDate}>{moment(publishDate).format('MMMM D, YYYY')}</div>
      </div>
    </div>
  );
};

PressCard.propTypes = {
  heroImage: object.isRequired,
  title: string,
  tag: string,
  publication: string,
  publishDate: string,
  externalUrl: string,
  containerRef: object,
  id: number.isRequired,
  className: string,
  onClick: func,
  hideInquireButton: bool,
};

PressCard.defaultProps = {
  title: null,
  tag: null,
  publication: null,
  publishDate: null,
  externalUrl: null,
  containerRef: object,
  className: null,
  onClick: () => {},
  hideInquireButton: false,
};

export default PressCard;
