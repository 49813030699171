import React, { useState } from 'react';
import { compose } from 'redux';
import classNames from 'classnames';
import { SwiperSlide } from 'swiper/react';
import { withViewport } from '../../util/contextHelpers';
import strapi from '../../util/strapi';
import SwiperCarousel from '../../components/SwiperCarousel/SwiperCarousel';
import ResponsiveImage from '../../components/ResponsiveImage/ResponsiveImage';
import ExternalLink from '../../components/ExternalLink/ExternalLink';
import { object } from 'prop-types';
import { getUrlImgStrapi } from '../../util/urlHelpers';
import Skeleton from '../../components/Skeleton/Skeleton';

import css from './SectionCarousel.module.css';

const VIDEO_PREFIX = 'video/';
const CAROUSEL_UNIQUE_GLOBAL_CLASS = 'homepageCarouselClass';

const SectionCarousel = props => {
  const { isLoading, heroData, isMobileLayout } = props;

  if (isLoading) {
    return <Skeleton className={css.heroSkeleton} />;
  }

  const [isHover, setIsHover] = useState(false);

  const { mobileCarousel, desktopCarousel } = heroData || {};

  const slideItems = carouselContent => {
    if (!carouselContent?.length) return null;

    return carouselContent.map((item, index) => {
      const {
        title,
        subtitle,
        url: heroUrl,
        image: {
          data: {
            attributes: { url, alternativeText, provider, mime },
          },
        },
      } = item;

      const mediaUrl = getUrlImgStrapi(provider, url);
      const isVideoBanner = mime.includes(VIDEO_PREFIX);

      const image = !isVideoBanner ? strapi.denormalisedImage({ data: item.image.data }) : null;

      return (
        <SwiperSlide key={`landing-carousel-image-${index}`} className={css.swiperWrapper}>
          <ExternalLink href={heroUrl.link} className={css.wrapper}>
            <div className={css.swiperCarousel}>
              <div  className={css.heroImage}>
                {isVideoBanner ? (
                  <div className={css.carouselVideoWrapper}>
                    <video className={css.carouselVideo} preload="metadata" controls muted>
                      <source src={`${mediaUrl}#t=0.001`} type={mime} />
                    </video>
                  </div>
                ) : (
                  <div className={classNames(css.heroAspectWrapper)} key={item.id}>
                    <ResponsiveImage
                      image={image}
                      variants={['large', 'medium', 'thumbnail', 'origin']}
                      alt={alternativeText}
                      className={css.hover}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className={css.heroCarouselInfo}>
              <div className={css.heroCarouselTitle}>{title}</div>
              <div className={css.heroCarouselSubtitle}>{subtitle}</div>
            </div>
          </ExternalLink>
        </SwiperSlide>
      );
    });
  };

  const hasMultipleSlides = mobileCarousel.length > 1 && desktopCarousel.length > 1;

  return mobileCarousel.length && desktopCarousel.length ? (
    <div onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
      <SwiperCarousel
        className={classNames(css.heroCarouselWrapper, CAROUSEL_UNIQUE_GLOBAL_CLASS)}
        navigation={isHover && !isMobileLayout && hasMultipleSlides}
        pagination={hasMultipleSlides}
        loop
      >
        {isMobileLayout ? slideItems(mobileCarousel) : slideItems(desktopCarousel)}
      </SwiperCarousel>
    </div>
  ) : null;
};

SectionCarousel.propTypes = {
  artistStrapi: object,
};

export default compose(withViewport)(SectionCarousel);
