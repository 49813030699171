import { bool, object, func } from 'prop-types';
import React, { useRef, useState } from 'react';
import { FormattedMessage, intlShape } from 'react-intl';
import { SwiperSlide } from 'swiper/react';
import ExhibitionCard from '../../components/ExhibitionCard/ExhibitionCard';
import SwiperCarousel from '../../components/SwiperCarousel/SwiperCarousel';
import HomeExhibitionCard from './HomeExhibitionCard';
import { SecondaryButton } from '../../components/Button/Button';
import classNames from 'classnames';

import css from './GalleryPage.module.css';

const SectionExhibitions = props => {
  const { exhibitionsSection, isMobileLayout, intl, onResetEnquiryState } = props;

  if (!exhibitionsSection) return null;

  const [isHover, setIsHover] = useState(false);
  const prevButtonRef = useRef(null);
  const nextButtonRef = useRef(null);

  const { ctaButton, exhibitions } = exhibitionsSection;

  const handleDirectToCTAUrl = () => {
    if (typeof window !== 'undefined') {
      window.location.href = ctaButton.link;
    }
  };

  return exhibitions?.data.length ? (
    <>
      <div className={css.featuredDesignPartnersContainer}>
        <div className={css.sectionTitle}>
          <FormattedMessage id="GalleryPage.featuredExhibitions" />
        </div>

        <div onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
          <div
            className={classNames(css.swiperCarouselExhibitionsButtons, {
              [css.hidden]: !isHover,
            })}
          >
            <button
              className={classNames(
                css.swiperCarouselArtistsButton,
                css.swiperCarouselArtistsButtonPrev
              )}
              ref={prevButtonRef}
            ></button>
            <button
              className={classNames(
                css.swiperCarouselArtistsButton,
                css.swiperCarouselArtistsButtonNext
              )}
              ref={nextButtonRef}
            ></button>
          </div>

          <SwiperCarousel
            pagination
            navigation={
              !isMobileLayout
                ? {
                    nextEl: nextButtonRef.current,
                    prevEl: prevButtonRef.current,
                  }
                : false
            }
            className={css.swiperCarouselExhibitions}
            slidesPerView={1}
            spaceBetween={1}
            breakpoints={{
              768: {
                slidesPerView: 2,
                spaceBetween: 45,
              },
              1024: {
                slidesPerView: 1,
                spaceBetween: 1,
              },
            }}
            onBeforeInit={swiper => {
              if (isMobileLayout) {
                swiper.params.navigation.prevEl = prevButtonRef.current;
                swiper.params.navigation.nextEl = nextButtonRef.current;
              }
            }}
          >
            {exhibitions.data.map(exhibition => {
              return (
                <SwiperSlide key={exhibition.id} className={css.swiperCard}>
                  <div className={css.mobile}>
                    <ExhibitionCard
                      exhibition={exhibition.attributes}
                      onResetEnquiryState={onResetEnquiryState}
                      intl={intl}
                    />
                  </div>
                  <div className={css.desktop}>
                    <HomeExhibitionCard
                      exhibition={exhibition.attributes}
                      onResetEnquiryState={onResetEnquiryState}
                    />
                  </div>
                </SwiperSlide>
              );
            })}
          </SwiperCarousel>
        </div>
        <SecondaryButton onClick={handleDirectToCTAUrl} className={css.secondaryButton}>
          {ctaButton.label}
        </SecondaryButton>
      </div>
    </>
  ) : null;
};

SectionExhibitions.propsType = {
  exhibitionsSection: object.isRequired,
  isMobileLayout: bool,
  intl: intlShape.isRequired,
  onResetEnquiryState: func.isRequired,
};

export default SectionExhibitions;
