import { bool, func, object } from 'prop-types';
import React, { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { SwiperSlide } from 'swiper/react';
import PressCard from '../../components/PressCard/PressCard';
import SwiperCarousel from '../../components/SwiperCarousel/SwiperCarousel';
import { SecondaryButton } from '../../components/Button/Button';
import classNames from 'classnames';

import css from './GalleryPage.module.css';

const SectionPress = props => {
  const { pressSection, isMobileLayout, onResetEnquiryState } = props;

  if (!pressSection) return null;

  const [isHover, setIsHover] = useState(false);
  const prevButtonRef = useRef(null);
  const nextButtonRef = useRef(null);

  const { ctaButton, presses } = pressSection;

  const handleDirectToCTAUrl = () => {
    if (typeof window !== 'undefined') {
      window.location.href = ctaButton.link;
    }
  };

  return presses?.data.length ? (
    <>
      <div className={css.featuredDesignPartnersContainer}>
        <div className={css.sectionTitle}>
          <FormattedMessage id="GalleryPage.featuredPress" />
        </div>

        <div onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
          <div
            className={classNames(css.swiperCarouselPressButtons, {
              [css.hidden]: !isHover,
            })}
          >
            <button
              className={classNames(
                css.swiperCarouselArtistsButton,
                css.swiperCarouselArtistsButtonPrev
              )}
              ref={prevButtonRef}
            ></button>
            <button
              className={classNames(
                css.swiperCarouselArtistsButton,
                css.swiperCarouselArtistsButtonNext
              )}
              ref={nextButtonRef}
            ></button>
          </div>

          <SwiperCarousel
            pagination
            navigation={
              !isMobileLayout
                ? {
                    nextEl: nextButtonRef.current,
                    prevEl: prevButtonRef.current,
                  }
                : false
            }
            className={css.swiperCarouselPress}
            slidesPerView={1}
            spaceBetween={1}
            breakpoints={{
              768: {
                slidesPerView: 2,
                spaceBetween: 45,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 25,
              },
            }}
            onBeforeInit={swiper => {
              if (isMobileLayout) {
                swiper.params.navigation.prevEl = prevButtonRef.current;
                swiper.params.navigation.nextEl = nextButtonRef.current;
              }
            }}
          >
            {presses.data.map(press => {
              return (
                <SwiperSlide key={press.id} className={css.pressSwiperCard}>
                  <PressCard
                    id={press.id}
                    {...press.attributes}
                    hideInquireButton
                    containerRef={{}}
                    onResetEnquiryState={onResetEnquiryState}
                  />
                </SwiperSlide>
              );
            })}
          </SwiperCarousel>
        </div>

        <SecondaryButton onClick={handleDirectToCTAUrl} className={css.secondaryButton}>
          {ctaButton.label}
        </SecondaryButton>
      </div>
    </>
  ) : null;
};

SectionPress.propsType = {
  pressSection: object.isRequired,
  isMobileLayout: bool,
  onResetEnquiryState: func.isRequired,
};

export default SectionPress;
