import React from 'react';
import { func, number, object, string } from 'prop-types';
import showdown from 'showdown';
import RichTextCkEditor from '../../components/RichTextCkEditor/RichTextCkEditor';
import InquireButton from '../../components/InquireButton/InquireButton';
import classNames from 'classnames';
import { getUrlImgStrapi } from '../../util/urlHelpers';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import NamedLink from '../../components/NamedLink/NamedLink';
import { limitString } from '../../util/uiHelpers';
import { INQUIRE_PROJECT_TYPE } from '../../marketplace-custom-config';
import config from '../../config';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

import css from './HomeProjectCard.module.css';

const LIMIT_NUMBER_OF_RICH_TEXT = 40;
const VIDEO_PREFIX = 'video/';

const ProjectImage = props => <img {...props} />;
const LazyImage = lazyLoadWithDimensions(ProjectImage, { loadAfterInitialRendering: 3000 });

const HomeProjectCard = props => {
  const { project, containerRef, textSliceLimit, onResetEnquiryState } = props;
  const { heroBanner, title, tag, richText, id, slug } = project;
  const banner = heroBanner.data[0].attributes;
  const bannerUrl = getUrlImgStrapi(banner.provider, banner.url);
  const bannerAlt = banner.alternativeText;
  const isVideoBanner = banner.mime.includes(VIDEO_PREFIX);
  const limitRichText = limitString(richText, textSliceLimit);

  const mdConverter = new showdown.Converter({
    noHeaderId: true,
  });
  const blogContentHtml = mdConverter.makeHtml(limitRichText);

  const hyperlink = `${config.canonicalRootURL}${createResourceLocatorString(
    'ProjectPostPage',
    routeConfiguration(),
    {
      slug,
    }
  )}`;

  return (
    <NamedLink name="ProjectPostPage" params={{ slug }} className={css.homeProjectWrapper}>
      <div className={css.heroWrapper}>
        <div className={css.threeToTwoWrapper}>
          <div className={classNames(css.aspectWrapper)}>
            {isVideoBanner ? (
              <div className={css.videoWrapper}>
                <video className={css.video}>
                  <source src={`${bannerUrl}#t=0.001`} type={banner.mime} />
                </video>
              </div>
            ) : (
              <LazyImage
                className={css.rootForImage}
                alt={bannerAlt}
                src={bannerUrl}
                containerRef={containerRef}
              />
            )}
          </div>
        </div>
      </div>

      <div className={css.homeExhibitionInfoColumn}>
        <h3 className={css.homeExhibitionTitle}>{title}</h3>

        <div className={css.tag}>{tag}</div>
        <InquireButton
          className={css.inquireButton}
          modalId={`homepage-project-${id}`}
          title={title}
          type={INQUIRE_PROJECT_TYPE}
          hyperlink={hyperlink}
          onClose={onResetEnquiryState}
        />
      <RichTextCkEditor className={css.richContent} contentHtml={blogContentHtml} />
      </div>
    </NamedLink>
  );
};

HomeProjectCard.propTypes = {
  project: object,
  containerRef: object,
  textSliceLimit: number,
  onResetEnquiryState: func.isRequired,
};

HomeProjectCard.defaultProps = {
  project: null,
  containerRef: null,
  textSliceLimit: LIMIT_NUMBER_OF_RICH_TEXT,
};

export default HomeProjectCard;
