import { bool, array, func } from 'prop-types';
import React, { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { SwiperSlide } from 'swiper/react';
import Skeleton from '../../components/Skeleton/Skeleton';
import SwiperCarousel from '../../components/SwiperCarousel/SwiperCarousel';
import { SecondaryButton } from '../../components/Button/Button';
import NamedLink from '../../components/NamedLink/NamedLink';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import InquireButton from '../../components/InquireButton/InquireButton';
import config from '../../config';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { INQUIRE_ARTIST_TYPE } from '../../marketplace-custom-config';
import { getUrlImgStrapi } from '../../util/urlHelpers';

import css from './GalleryPage.module.css';

const ARTISTS_CAROUSEL_UNIQUE_GLOBAL_CLASS = 'homepageArtistsCarouselClass';

const Image = props => <img {...props} loading="lazy" />;
const LazyImage = lazyLoadWithDimensions(Image, { loadAfterInitialRendering: 3000 });

const getStrapiImageProps = image => ({
  src: getUrlImgStrapi(image.provider, image.url),
  alt: image.alternativeText,
});

const FeaturedArtists = props => {
  const { isLoading, featuredArtists, artistsSection, isMobileLayout, onResetEnquiryState } = props;
  const { ctaButton, artists } = artistsSection || {};

  if (isLoading || !featuredArtists.length || !ctaButton) {
    return <Skeleton className={css.featuredArtistsSkeleton} />;
  }

  const [isHover, setIsHover] = useState(false);
  const prevButtonRef = useRef(null);
  const nextButtonRef = useRef(null);

  const slideItems = featuredArtists.map(artist => {
    const {
      id,
      attributes: {
        profile: { displayName },
      },
      listing,
    } = artist;
    const strapiArtistInfo = artists.data.find(strapiArtist => strapiArtist.attributes.artistId === id.uuid);
    const { mainImage, hoverImage } = strapiArtistInfo.attributes;

    const {
      attributes: {
        publicData: { state, country, parsedArtistName },
      },
    } = listing;

    const pageName = parsedArtistName ? 'ProfilePage' : 'ProfileBasePage';
    const pageParams = {
      name: pageName,
      params: {
        name: parsedArtistName,
      },
      useAbsoluteUrl: true,
      isNewTab: true,
    };

    const hyperlink = `${config.canonicalRootURL}${createResourceLocatorString(
      pageName,
      routeConfiguration(),
      { name: parsedArtistName }
    )}`;

    return (
      <SwiperSlide key={id.uuid} className={classNames(css.cardWrapper)}>
        <NamedLink {...pageParams}>
          <div className={classNames(css.aspectWrapper, css.featuredArtistsAspectWrapper)}>
            <LazyImage {...getStrapiImageProps(hoverImage.data.attributes)} />
            <LazyImage
              {...getStrapiImageProps(mainImage.data.attributes)}
              className={css.mainImage}
            />
          </div>
          <div className={classNames(css.cardTitle, css.textHover)}>{displayName}</div>
          <div className={classNames(css.location)}>{`${state}, ${country}`}</div>
        </NamedLink>
        <InquireButton
          modalId={`Artist-card-gallery-${id.uuid}`}
          title={displayName}
          hyperlink={hyperlink}
          type={INQUIRE_ARTIST_TYPE}
          onClose={onResetEnquiryState}
        />
      </SwiperSlide>
    );
  });

  const handleDirectToCTAUrl = () => {
    if (typeof window !== 'undefined') {
      window.location.href = ctaButton.link;
    }
  };

  return (
    <div className={css.featuredArtistsLayoutWrapper}>
      <div className={css.sectionTitle}>
        <FormattedMessage id="GalleryPage.featuredArtists" />
      </div>

      <div onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
        <div className={classNames(css.swiperCarouselArtistsButtons, {
          [css.hidden]: !isHover
        })}>
          <button
            className={classNames(
              css.swiperCarouselArtistsButton,
              css.swiperCarouselArtistsButtonPrev
            )}
            ref={prevButtonRef}
          ></button>
          <button
            className={classNames(
              css.swiperCarouselArtistsButton,
              css.swiperCarouselArtistsButtonNext
            )}
            ref={nextButtonRef}
          ></button>
        </div>

        <SwiperCarousel
          pagination
          navigation={
            !isMobileLayout
              ? {
                  nextEl: nextButtonRef.current,
                  prevEl: prevButtonRef.current,
                }
              : false
          }
          className={classNames(
            css.swiperCarousel,
            css.swiperCarouselArtists,
            ARTISTS_CAROUSEL_UNIQUE_GLOBAL_CLASS
          )}
          slidesPerView={1}
          spaceBetween={1}
          breakpoints={{
            768: {
              slidesPerView: 2,
              spaceBetween: 45,
              slidesPerGroup: 2,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 25,
              slidesPerGroup: 3,
            },
          }}
          onBeforeInit={swiper => {
            swiper.params.navigation.prevEl = prevButtonRef.current;
            swiper.params.navigation.nextEl = nextButtonRef.current;
          }}
        >
          {slideItems}
        </SwiperCarousel>
      </div>
      <SecondaryButton onClick={handleDirectToCTAUrl} className={css.secondaryButton}>
        {ctaButton.label}
      </SecondaryButton>
    </div>
  );
};

FeaturedArtists.defaultProps = {
  featuredArtists: [],
  isLoading: false,
  isMobileLayout: false,
};

FeaturedArtists.propTypes = {
  featuredArtists: array,
  isLoading: bool,
  isMobileLayout: bool,
  onResetEnquiryState: func,
};

export default FeaturedArtists;
