import React from 'react';
import { func, object } from 'prop-types';
import InquireButton from '../../components/InquireButton/InquireButton';
import showdown from 'showdown';
import RichTextCkEditor from '../../components/RichTextCkEditor/RichTextCkEditor';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import classNames from 'classnames';
import { getUrlImgStrapi } from '../../util/urlHelpers';
import { limitString } from '../../util/uiHelpers';
import NamedLink from '../../components/NamedLink/NamedLink';
import {getExhibitionTagLabel} from '../../util/dates';
import { FormattedMessage } from 'react-intl';
import config from '../../config';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { INQUIRE_EXHIBITION_TYPE } from '../../marketplace-custom-config';

import css from './HomeProjectCard.module.css';

const VIDEO_PREFIX = 'video/';
const WORDS_LIMIT = 40;

const ProjectImage = props => <img {...props} />;
const LazyImage = lazyLoadWithDimensions(ProjectImage, { loadAfterInitialRendering: 3000 });

const HomeExhibitionCard = props => {
  const { exhibition, onResetEnquiryState } = props;
  const { heroImg, title, slug, richContent, startDate, endDate } = exhibition || {};

  const banner = heroImg.data[0].attributes;
  const bannerUrl = getUrlImgStrapi(banner.provider, banner.url);
  const bannerAlt = banner.alternativeText;
  const isVideoBanner = banner.mime.includes(VIDEO_PREFIX);
  const tagLabelId = getExhibitionTagLabel(startDate, endDate);

  const limitRichText = limitString(richContent, WORDS_LIMIT);

  const mdConverter = new showdown.Converter({
    noHeaderId: true,
  });
  const blogContentHtml = mdConverter.makeHtml(limitRichText);
  const hyperlink = `${config.canonicalRootURL}${createResourceLocatorString(
    'ExhibitionsPostPage',
    routeConfiguration(),
    { slug }
  )}`;

  return (
    <div
      name="ExhibitionsPostPage"
      params={{
        slug,
      }}
      className={css.homeExhibitionWrapper}
    >
      <div className={css.homeExhibitionInfoColumn}>
        <h3 className={css.homeExhibitionTitle}>{title}</h3>

        <div className={css.tag}>
          <FormattedMessage id={`ExhibitionCard.${tagLabelId}`} />
        </div>

        <InquireButton
          modalId={`homepage-exhibition-${slug}`}
          title={title}
          hyperlink={hyperlink}
          type={INQUIRE_EXHIBITION_TYPE}
          onClose={onResetEnquiryState}
        />

        <RichTextCkEditor className={css.richContent} contentHtml={blogContentHtml} />
      </div>

      <NamedLink
        name="ExhibitionsPostPage"
        params={{
          slug,
        }}
        className={css.heroWrapper}
      >
        <div className={css.threeToTwoWrapper}>
          <div className={classNames(css.aspectWrapper)}>
            {isVideoBanner ? (
              <div className={css.videoWrapper}>
                <video className={css.video}>
                  <source src={`${bannerUrl}#t=0.001`} type={banner.mime} />
                </video>
              </div>
            ) : (
              <LazyImage className={css.rootForImage} alt={bannerAlt} src={bannerUrl} />
            )}
          </div>
        </div>
      </NamedLink>
    </div>
  );
};

HomeExhibitionCard.propTypes = {
  exhibition: object.isRequired,
  onResetEnquiryState: func.isRequired,
};

export default HomeExhibitionCard;
