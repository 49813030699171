import config from '../../config';
const siteImage = config.heroImages.heroImageConfig.src;

export const createSearchResultSchema = ({intl, metaDescription}) => {
  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const searchAddress = intl.formatMessage({ id: 'GalleryPage.schemaMapSearch' });
  const schemaDescription = metaDescription || intl.formatMessage({
    id: 'GalleryPage.schemaDescription',
  });
  const schemaTitle = intl.formatMessage(
    { id: 'GalleryPage.schemaTitle' },
    { searchAddress, siteTitle }
  );
  const schemaImage = `${config.canonicalRootURL}${siteImage}`;

  const schemaMainEntity = JSON.stringify({
    '@type': 'ItemList',
    name: searchAddress,
    itemListOrder: 'http://schema.org/ItemListOrderAscending',
  });
  return {
    title: schemaTitle,
    description: schemaDescription,
    facebookImages: [{ url: schemaImage, width: 1200, height: 630 }],
    twitterImages: [{ url: schemaImage, width: 600, height: 314 }],
    schema: {
      '@context': 'http://schema.org',
      '@type': 'SearchResultsPage',
      description: schemaDescription,
      name: schemaTitle,
      mainEntity: [schemaMainEntity],
      image: [schemaImage],
    },
  };
};
