import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Modal from '../Modal/Modal';
import { sendEnquiry } from '../../ducks/Inquire.duck';
import { ensureUser } from '../../util/data';
import EnquiryForm from '../../forms/EnquiryForm/EnquiryForm';
import { FormattedMessage, injectIntl } from 'react-intl';
import IconInquire from '../IconInquire/IconInquire';
import { compose } from 'redux';
import { bool, func, number, oneOfType, string } from 'prop-types';
import { propTypes } from '../../util/types';
import { intlShape } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './InquireButton.module.css';

const InquireButton = props => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const {
    isAuthenticated,
    onManageDisableScrolling,
    currentUser,
    onSendEnquiry,
    onSendEmail,
    sendEnquiryInProgress,
    sendEnquiryError,
    sendEnquirySuccess,
    customSuccessMsg,
    customContent,
    onSubmit,
    onClose,
    intl,
    isOpen,
    modalId,
    usePortal,
    buttonMsg,
    className,
    title,
    hyperlink,
    type,
  } = props;

  useEffect(() => {
    setIsOpenModal(isOpen);
  }, [isOpen]);

  const handleSubmit = values => {
    const { name, email, message } = values;

    const {
      attributes: { profile, email: userEmail },
    } = ensureUser(currentUser);

    const params = {
      name: name || profile.displayName,
      email: email || userEmail,
      message: message.trim(),
      title,
      hyperlink,
      type,
    };

    onSubmit(params);
    onSendEnquiry({ onSendEmail, ...params });
  };

  const handleClose = e => {
    e.stopPropagation();
    onClose();
    setIsOpenModal(false);
  };

  const successMsg = customSuccessMsg || (
    <div className={css.successMsg}>
      <FormattedMessage id="InquireButton.successMsg" />
    </div>
  );

  const content = customContent || (
    <>
      <div className={css.title}>
        <FormattedMessage id="InquireButton.title" />
      </div>
      <EnquiryForm
        onSubmit={handleSubmit}
        inProgress={sendEnquiryInProgress}
        error={sendEnquiryError}
        isAuthenticated={isAuthenticated}
      />
    </>
  );

  return (
    <div
      className={classNames(css.root, className)}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <div
        className={css.inquireWrapper}
        onClick={() => {
          setIsOpenModal(true);
        }}
      >
        <IconInquire />
        <span>{buttonMsg || intl.formatMessage({ id: 'InquireButton.inquire' })}</span>
      </div>
      <Modal
        id={`InquireButton.enquiryModal-${modalId}`}
        isOpen={isOpenModal}
        onClose={handleClose}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal={usePortal}
      >
        {sendEnquirySuccess ? successMsg : content}
      </Modal>
    </div>
  );
};

InquireButton.propTypes = {
  isAuthenticated: bool,
  onManageDisableScrolling: func,
  currentUser: propTypes.currentUser,
  onSendEnquiry: func.isRequired,
  sendEnquiryInProgress: bool,
  sendEnquiryError: propTypes.error,
  sendEnquirySuccess: bool,
  intl: intlShape.isRequired,
  isOpen: bool,
  modalId: oneOfType(string, number),
  usePortal: bool,
  onSubmit: func,
  onClose: func,
  buttonMsg: string,
  className: string,
  onSendEmail: func,
  title: string,
  hyperlink: string,
  type: string,
};

InquireButton.defaultProps = {
  isAuthenticated: false,
  currentUser: null,
  sendEnquiryInProgress: false,
  sendEnquiryError: null,
  sendEnquirySuccess: false,
  isOpen: false,
  modalId: null,
  usePortal: true,
  buttonMsg: null,
  className: null,
  onSendEmail: undefined,
  title: null,
  hyperlink: null,
  type: null,
  onSubmit: () => {},
  onClose: () => {},
  onManageDisableScrolling: () => {},
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const { currentUser } = state.user;
  const { sendEnquiryInProgress, sendEnquiryError, sendEnquirySuccess } = state.Inquire;

  return {
    isAuthenticated,
    currentUser,
    sendEnquiryInProgress,
    sendEnquiryError,
    sendEnquirySuccess,
  };
};

const mapDispatchToProps = dispatch => ({
  onSendEnquiry: params => dispatch(sendEnquiry(params)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), injectIntl)(InquireButton);
