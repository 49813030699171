import { Swiper } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay, Pagination, Virtual, Scrollbar } from 'swiper/core';
import React from 'react';
SwiperCore.use([Navigation, Pagination, Autoplay, Virtual, Scrollbar]);
import css from './SwiperCarousel.module.css';
import classNames from 'classnames';
import { string } from 'prop-types';

function SwiperCarousel(props) {
  const { children, rootClassName, ...restProps } = props;

  return (
    <Swiper className={classNames(css.root, rootClassName)} {...restProps}>
      {children}
    </Swiper>
  );
}
SwiperCarousel.defaultProps = {
  rootClassName: null,
};
SwiperCarousel.propTypes = {
  rootClassName: string,
};
export default SwiperCarousel;
