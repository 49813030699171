import { bool, func, object } from 'prop-types';
import React, { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { SwiperSlide } from 'swiper/react';
import SwiperCarousel from '../../components/SwiperCarousel/SwiperCarousel';
import { SecondaryButton } from '../../components/Button/Button';
import ProjectCard from '../../components/ProjectCard/ProjectCard';
import HomeProjectCard from './HomeProjectCard';
import classNames from 'classnames';

import css from './GalleryPage.module.css';

const SectionProjects = props => {
  const { projectsSection, isMobileLayout, onManageDisableScrolling, onResetEnquiryState } = props;

  if (!projectsSection) return null;

  const [isHover, setIsHover] = useState(false);
  const prevButtonRef = useRef(null);
  const nextButtonRef = useRef(null);

  const { ctaButton, projects } = projectsSection;

  const handleDirectToCTAUrl = () => {
    if (typeof window !== 'undefined') {
      window.location.href = ctaButton.link;
    }
  };

  return projects?.data.length ? (
    <>
      <div className={css.featuredDesignPartnersContainer}>
        <div className={css.sectionTitle}>
          <FormattedMessage id="GalleryPage.featuredProjects" />
        </div>

        <div onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
          <div
            className={classNames(css.swiperCarouselExhibitionsButtons, {
              [css.hidden]: !isHover,
            })}
          >
            <button
              className={classNames(
                css.swiperCarouselArtistsButton,
                css.swiperCarouselArtistsButtonPrev
              )}
              ref={prevButtonRef}
            ></button>
            <button
              className={classNames(
                css.swiperCarouselArtistsButton,
                css.swiperCarouselArtistsButtonNext
              )}
              ref={nextButtonRef}
            ></button>
          </div>

          <SwiperCarousel
            pagination
            navigation={
              !isMobileLayout
                ? {
                    nextEl: nextButtonRef.current,
                    prevEl: prevButtonRef.current,
                  }
                : false
            }
            className={css.swiperCarouselExhibitions}
            slidesPerView={1}
            spaceBetween={1}
            breakpoints={{
              768: {
                slidesPerView: 2,
                spaceBetween: 45,
              },
              1024: {
                slidesPerView: 1,
                spaceBetween: 1,
              },
            }}
            onBeforeInit={swiper => {
              if (isMobileLayout) {
                swiper.params.navigation.prevEl = prevButtonRef.current;
                swiper.params.navigation.nextEl = nextButtonRef.current;
              }
            }}
          >
            {projects.data.map(project => {
              return (
                <SwiperSlide key={project.id} className={css.swiperCard}>
                  <div className={css.mobile}>
                    <ProjectCard
                      project={project}
                      onManageDisableScrolling={onManageDisableScrolling}
                      onResetEnquiryState={onResetEnquiryState}
                    />
                  </div>
                  <div className={css.desktop}>
                    <HomeProjectCard
                      project={project.attributes}
                      onManageDisableScrolling={onManageDisableScrolling}
                      showTestimonial
                      isMobileLayout={isMobileLayout}
                      onResetEnquiryState={onResetEnquiryState}
                    />
                  </div>
                </SwiperSlide>
              );
            })}
          </SwiperCarousel>
        </div>

        <SecondaryButton onClick={handleDirectToCTAUrl} className={css.secondaryButton}>
          {ctaButton.label}
        </SecondaryButton>
      </div>
    </>
  ) : null;
};

SectionProjects.propsType = {
  projectsSection: object.isRequired,
  isMobileLayout: bool,
  onManageDisableScrolling: func,
  onResetEnquiryState: func.isRequired,
};

export default SectionProjects;
