import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconInquire.module.css';

const IconInquire = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg" className={classes}>
      <rect fill="none" height="256" width="256" />
      <path
        d="M149.7,195.9l-14.8,24.7a8.1,8.1,0,0,1-13.8,0l-14.8-24.7a7.9,7.9,0,0,0-6.8-3.9H40a8,8,0,0,1-8-8V56a8,8,0,0,1,8-8H216a8,8,0,0,1,8,8V184a8,8,0,0,1-8,8H156.5A7.9,7.9,0,0,0,149.7,195.9Z"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="12"
      />
    </svg>
  );
};

IconInquire.defaultProps = {
  rootClassName: null,
  className: null,
};

IconInquire.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconInquire;
